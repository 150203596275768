<template>
  <div>
    <v-container
      grid-list-xs
      class="px-0"
    >
      <v-row>
        <v-col cols="12">
          <table class="pt-5">
            <!--NOMBRES-->
            <tr>
              <th
                colspan="2"
                class="header"
                style="min-width:140px"
              >
                <p
                  class="mb-0 pl-2 text-uppercase"
                  style="font-size: 14px;"
                >
                  {{ $t('permiso.tableros') }}
                </p>
              </th>
              <template v-for="(iten, index) in $store.state.app.sourceTableros">
                <th
                  :key="Math.random() * (index + 1)"
                  colspan="4"
                  class=""
                >
                  <div class="d-flex justify-center">
                    {{ iten.name }}
                  </div>
                </th>
                <th
                  :key="Math.random() * (index + 1)"
                  style="min-width: 50px;"
                  class="header_top"
                ></th>
              </template>
            </tr>

            <tr
              v-for="(table, index) in tableros"
              :key="Math.random() * (index + 1)"
            >
              <td
                colspan="2"
                class="leftColMine header"
                style="min-width:140px"
              >
                <p
                  class="mb-0 pl-2"
                  style="font-size: 14px;"
                >
                  {{ table.name }}
                </p>
              </td>
              <template v-for="(ite, indexI) in $store.state.app.sourceTableros">
                <ItemsTab
                  :key="indexI"
                  :index="indexI"
                  :tablero="table.id"
                />
              </template>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiLock,
} from '@mdi/js'
import ItemsTab from '../utils/tableros/Items.vue'

export default {
  components: {
    ItemsTab,
  },
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      tableros: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiLock,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      isDialogVisibleCreateAdmin: false,
      email: null,
      admin: {},
      is_nivel_propietario: false,
      is_creator: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
    this.getPerfiles()
    this.getTableros()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getPerfiles() {
      this.axios
        .get('perfil_afiliado/permisos', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.items = res.data.data
            this.$store.state.app.sourceTableros = this.items

            // console.log(this.$store.state.app.sourceTableros)
          }
        })
        // eslint-disable-next-line no-return-assign
        // .finally(() => this.setPermisosTabLoading(false))
    },
    getTableros() {
      this.axios
        .get('tableros?per_page=1000',
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.tableros = res.data.data
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>

<template>
  <div>
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${itemDelete.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { item: itemDelete.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialogDelete()"
          >
            {{ $t('btn.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.perfil') }}
            </th>
            <th
              width="40%"
              class="text-uppercase"
            >
              {{ $t('lbl.description') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.active') }}
            </th>
            <th
              v-if="is_nivel_propietario"
              class="text-uppercase"
            >
              {{ $t('lbl.showFront') }}
            </th>
            <!--<th class="text-uppercase">
              {{ $t('menu.permisos') }}
            </th>-->
            <th
              v-if="
                permisos.includes('perfil_afiliado:edit') ||
                  permisos.includes('perfil_afiliado:destroy') ||
                  permisos.includes('*')
              "
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              {{ iten.name }}
            </td>
            <td>
              {{ iten.description }}
            </td>
            <td>
              <v-switch
                v-model="iten.active"
                :disabled="
                  !permisos.includes('perfil_afiliado:edit') &&
                    !permisos.includes('*') ||
                    (iten.user_create_id !== user.id && iten.user_create_id !== user.id_father)
                "
                @change="editActive(iten)"
              ></v-switch>
            </td>
            <td v-if="is_nivel_propietario">
              <v-switch
                v-model="iten.show_front"
                :disabled="!permisos.includes('perfil_afiliado:edit') && !permisos.includes('*')"
                @change="editShow(iten)"
              ></v-switch>
            </td>
            <!--<td>
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showModal(iten)"
                  >
                    <v-icon>{{ icons.mdiFormatListText }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('menu.permisos') }}</span>
              </v-tooltip>
            </td>-->
            <td
              v-if="
                permisos.includes('perfil_afiliado:edit') ||
                  permisos.includes('perfil_afiliado:destroy') ||
                  permisos.includes('*')
              "
            >
              <!--<v-tooltip
                v-if="(permisos.includes('perfil_afiliado:edit') || permisos.includes('*')) && is_nivel_propietario"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="showCreateAdmin(iten)"
                  >
                    <v-icon>{{ icons.mdiLock }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('lbl.createAdmin') }}</span>
              </v-tooltip>-->

              <v-tooltip
                v-if="(permisos.includes('perfil_afiliado:edit') ||
                  permisos.includes('*')) && (
                  user.nivel === -1 ||
                  iten.user_create_id === user.id ||
                  iten.user_create_id === user.id_father
                )"
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="(permisos.includes('perfil_afiliado:destroy') ||
                  permisos.includes('*'))&& (
                  iten.user_create_id === user.id ||
                  iten.user_create_id === user.id_father
                )"
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiLock,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiLock,
      },
      isDialogVisible: false,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
      isDialogVisibleCreateAdmin: false,
      email: null,
      admin: {},
      is_nivel_propietario: false,
      is_creator: false,
      user: {},
    }
  },
  mounted() {
    this.profile()
    this.load()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .get(`perfil_afiliado?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.search}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
          }
        })
    },
    onPageChange() {
      this.load()
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      } else {
        this.abilities = []
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    loadPermisos() {
      this.abilityList = []
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const items = res.data.data
          // eslint-disable-next-line no-plusplus
          for (let indexA = 0; indexA < this.abilities.length; indexA++) {
            const ability = this.abilities[indexA]
            if (ability === '*') {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                this.abilityList.push(element.name)
              }
            } else {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                if (element.ability === ability) {
                  this.abilityList.push(element.name)
                }
              }
            }
          }
          // eslint-disable-next-line no-return-assign
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoad = false))
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    closeDialogDelete() {
      this.isDialogVisibleDelete = !this.isDialogVisibleDelete
      this.itemDelete = {}
    },
    newItem() {
      sessionStorage.removeItem('perfil-afiliado-id')
      this.$router.push({ name: 'perfil-afiliado-update' })
    },
    editItem(iten) {
      sessionStorage.setItem('perfil-afiliado-id', iten.id)
      this.$router.push({ name: 'perfil-afiliado-update' })
    },
    editActive(iten) {
      this.axios
        .post(
          `perfil_afiliado/active/${iten.id}`,
          { active: iten.active },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.itemDelete = iten
    },
    confirmDelete() {
      this.axios
        .delete(`perfil_afiliado/${this.itemDelete.id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.load()
          }
        })
        .finally(() => this.closeDialogDelete())
    },
    showCreateAdmin(iten) {
      this.isDialogVisibleCreateAdmin = true
      this.admin = iten
    },
    createAdmin() {
      if (this.email) {
        this.axios
          .post(
            'prestatario',
            { id_perfil: this.admin.id, email: this.email },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 401) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else if (response.data.data.status === 402) {
                this.$toast.error(this.$t('msg.userExist', { email: this.email }))
              }
            } else {
              this.$toast.info(this.$t('msg.sendEmail', { email: this.email }))
              this.email = null
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isDialogVisibleCreateAdmin = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    editShow(iten) {
      this.axios
        .post(
          `perfil_afiliado/show-front/${iten.id}`,
          { show_front: iten.show_front },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>

<template>
  <fragment>
    <!--LIST-->
    <td
      :key="Math.random() * (index + 1)"
      colspan="4"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <!--
          @click="checkPerm(index, 'nom_aerolines:list')"
        -->
        <v-checkbox
          v-model="checkboxVer"
          hide-details
          class="my-1"
          disabled
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    tablero: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourceTableros: state => state.app.sourceTableros,
    }),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      checkboxVer: false,
    }
  },
  created() {
    this.checkboxVer = this.sourceTableros[this.index].tableros.includes(this.tablero)
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkPerm() {
      const { tableros } = this.sourceTableros[this.index]
      if (this.checkboxVer) {
        tableros.push(this.tablero)
      } else {
        tableros.splice(tableros.indexOf(this.tablero), 1)
      }
    },
  },
}
</script>

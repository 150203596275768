<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <PerfilesList></PerfilesList>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PerfilesList from './tablas/PerfilesList.vue'

export default {
  components: {
    PerfilesList,
  },
}
</script>
